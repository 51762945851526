<template>
  <!-- 这个组件不会被keepAlive影响 -->
  <fragment>
    <slot v-if="reloadCtrl"></slot>
  </fragment>
</template>

<script>
export default {
  data() {
    return {
      reloadCtrl: true,
    }
  },
  activated() {
    this.reloadCtrl = false
    this.$nextTick(() => {
      this.reloadCtrl = true
    })
  },
}
</script>

<style></style>
