const getters = {
  device: state => state.app.device,

  avatar: state => state.user.avatar,
  nickname: state => state.user.nickname,
  // 是否是超级管理员
  isSAdmin: state => {
    let isSAdmin = false
    state.user.roleList.map(role => {
      if (role.isAdmin) {
        isSAdmin = true
      }
    })
    return isSAdmin
  }
}

export default getters
