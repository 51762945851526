import { permissionMenuRoutes, asyncRouterMapRoot, processedDefaultMenus } from '@/routes'
import treeUtils from '@/utils/treeUtils'
import { nanoid } from 'nanoid'
/**
 *
 * @param {*} apiMenus 接口返回的menu
 * @returns 基于route的menuData
 */
function generateMenuData(apiMenus) {
  let menuData = treeUtils.map(apiMenus, (item) => {
    const uniq = item.menuCode || nanoid()
    // path必须是标准的vue路由path且唯一,url为原始配置地址。
    let path = encodeURI(`/m/__${uniq}__`)
    let url = item.menuUrl
    // 如果填写的是标准路径，直接使用，且不再解析原始配置地址
    if (item.menuUrl.startsWith('/')) {
      path = item.menuUrl
      url = ''
    }

    const actions = []
    if (item.children) {
      item.children.forEach((child) => {
        if (child.menuType === 'A') {
          actions.push({
            name: child.menuName,
            code: child.menuCode,
          })
        }
      })
      // 指定哪些菜单为目录（需要重定向的）
      // if (item.children.some((child) => child.menuType === 'C')) {
      //   item.menuType = 'M'
      // }
    }
    return {
      path: path,
      meta: {
        title: item.menuName,
        icon: item.menuIcon,
        type: item.menuType,
        code: item.menuCode,
        url: url,
        target: item.menuTarget,
        actions,
        hidden: item.menuHidden,
        extra: {
          id: item.id,
        },
      },
    }
  })
  menuData = treeUtils.filter(menuData, (item) => {
    return item.menuType !== 'A'
  })
  return menuData
}
/**
 * 创建用户的menu,并用预设的前端menu补充信息
 * 本地路由集中有的，但远程菜单没有的，会自动添加hidden
 * @param {*} localRouteMap 本地路由集
 * @param {*} menu 用户菜单
 */
function generateMenu(localRouteMap, menu, modules) {
  const r = {}
  treeUtils.forEach(localRouteMap, (route) => {
    if (route.path) {
      r[route.path] = {
        ...route,
      }
    }
  })
  // 补全menu信息(会添加子路由，但是只循环menu)
  treeUtils.forEach(
    menu,
    (m) => {
      const localRoute = r[m.path]
      // 存在本地路由
      if (localRoute) {
        for (const j in m.meta) {
          if (localRoute.meta[j]) {
            m.meta[j] = localRoute.meta[j]
          }
        }

        m.component = localRoute.component
        if (localRoute.children) {
          // 菜单中隐藏本地路由
          treeUtils.forEach(localRoute.children, (child) => {
            child.meta.hidden = true
          })
          m.children = localRoute.children
          m.skip = true
        }
        if (localRoute.redirect) {
          m.redirect = localRoute.redirect
        }
      }
    },
    {
      skip: (item) => {
        return item.skip
      },
    }
  )
  // 补全redirect和component(循环menu 和添加的 子路由)
  const pageWebview = () => import('@/pages/Webview')
  const page404 = () => import('@/pages/exception/404')
  // 补全redirect和component(循环menu 和添加的 子路由)
  treeUtils.forEach(menu, (m) => {
    // 处理无法解析到路由的url
    if (m.meta.url) {
      // 如果是需要外部打开的，则重定向；否则使用webview打开
      if (m.meta.target === 'blank') {
        m.redirect = {
          path: '/redirect',
          query: {
            url: m.meta.url,
          },
        }
      } else {
        m.component = pageWebview
      }
    }
    // 如果是目录，自动添加重定向到下级
    if (m.meta.type === 'M' && !m.redirect) {
      const activeChild = m.children?.find((routeChild) => !routeChild.meta.hidden)
      if (activeChild) {
        m.redirect = activeChild.path
      }
    }

    if (!(m.component || m.redirect)) {
      m.component = page404
    }
  })
  return menu
}
function generateAccessRouters(menus, routerMap) {
  const accessRouters = []
  const blankAccessRouters = []
  const rootPermissionRouters = routerMap.find((v) => v.path === '/m')
  const rootBlankPermissionRoutes = routerMap.find((v) => v.path === '/b')

  treeUtils.forEach(menus, (route) => {
    const { children, ...r } = route
    if (r.path) {
      accessRouters.push(r)
      blankAccessRouters.push({
        ...r,
        path: `/b${r.path}`,
        redirect: `/b${route.redirect}`,
      })
    }
  })

  rootPermissionRouters.children = accessRouters
  rootBlankPermissionRoutes.children = blankAccessRouters
  return routerMap
}
const permission = {
  state: {
    asyncRouterMap: [],
    menus: [],
    modules: [], // modules 暂时没用
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.asyncRouterMap = routers
    },
    SET_MENUS: (state, menus) => {
      state.menus = menus
    },
    RESET_PERMISSION(state) {
      state.asyncRouterMap = []
      state.menus = []
      state.modules = []
    },
  },
  actions: {
    GenerateRoutes({ commit }, data) {
      return new Promise((resolve) => {
        const { roles } = data
        // menuData 只包含menu数据
        const menuData = generateMenuData(roles.permissions.menus)
        console.log(menuData)
        // 基于menuData 创建完整的routes 包含 redirect / component / meta
        const processedPermissionMenus = generateMenu(permissionMenuRoutes, menuData, roles.permissions.modules)
        // processedMenus 是完整的菜单
        const processedMenus = processedDefaultMenus.concat(processedPermissionMenus)
        // 基于菜单创建真正的路由
        const accessRouters = generateAccessRouters(processedMenus, asyncRouterMapRoot)
        const asyncRouterMap = [
          ...accessRouters,
          // 一个菜单都没有，重定向到403
          {
            path: '/',
            redirect: processedMenus[0] ? processedMenus[0].path : '/403',
          },
          {
            path: '*',
            redirect: '/404',
          },
        ]
        commit('SET_MENUS', processedMenus)
        commit('SET_ROUTERS', asyncRouterMap)
        resolve()
      })
    },
  },
}

export default permission
