<template>
  <div class="app-autoImg">
    <AutoImg @load="onLoad" :src="src" :fit="fit" style="width: 100%; height: 100%">
      <template #error>
        <div class="autoImg-error" title="加载图片失败">
          <svg class="autoImg-error-text" viewBox="0 0 85 20">
            <text x="0" y="16" fill="#666">加载图片失败</text>
          </svg>
        </div>
      </template>
      <template #loading><a-icon type="loading"></a-icon></template>
    </AutoImg>
    <div class="toolbar-preview" v-if="loaded && preview" @click.stop="doPreview(preview)">
      <div class="preview-icon">
        <app-icon type="icon-chakan" class="cursor-pointer"></app-icon>
      </div>
    </div>
  </div>
</template>

<script>
import AutoImg from './AutoImg'
export default {
  data() {
    return {
      loaded: false,
    }
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    fit: {
      type: String,
      default: '',
    },
    preview: {
      type: [Boolean, Object],
      default: false,
    },
  },
  components: {
    AutoImg,
  },
  methods: {
    onLoad() {
      this.loaded = true
    },
    doPreview(preview) {
      if (typeof preview === 'boolean') {
        this.$viewerApi({
          options: {
            inline: false,
            button: true,
            navbar: true,
            title: true,
            toolbar: false,
            tooltip: true,
            movable: true,
            zoomable: true,
            rotatable: true,
            scalable: true,
            transition: true,
            fullscreen: true,
            keyboard: true,
          },
          images: [this.src],
        })
      } else {
        this.$viewerApi(preview)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.app-autoImg {
  position: relative;
  display: inline-block;
  border-radius: 5px;
  overflow: hidden;
  .toolbar-preview {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25px;
    background: #000000;
    opacity: 0.7;
    color: @primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .autoImg-error {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .autoImg-error-text {
      width: 80%;
      max-width: 100px;
      pointer-events: none;
    }
  }
}
</style>
