import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'

import createPersistedState from 'vuex-persistedstate'
import createLogger from 'vuex/dist/logger'
// default router permission control
import permission from './modules/permission'

import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    permission,
  },
  state: {},
  mutations: {},
  actions: {},
  getters,
  plugins: [
    createPersistedState({
      paths: ['user'],
    }),
    createPersistedState({
      paths: ['app.tab'],
      storage: window.sessionStorage,
    }),
    createLogger(),
  ],
})
