import axios from 'axios'
import _ from 'lodash'
import qs from 'qs'
import EventEmitter from 'events'
import {
  getConfig,
  getAuth,
  getBaseUrl,
  showError,
  showLoading,
  showProgress,
  hideLoading,
  hideProgress,
  validResponse,
  is401,
  getErrorMessage,
  extraErrorHandle,
  extraResponseHandle,
  extraRequestHandle,
  doRefreshToken,
  doLogout,
} from './configFns.js'
const ee = new EventEmitter()

function eCodeNMsg(code) {
  const axiosErrorMap = {
    E_REQUEST_CANCEL: '请求已取消',
    E_HAS_RESOLVED: '忽略处理',
    E_UNEXCEPTED_RESPONESE: '数据异常',
    E_SERVER_ERROR: '服务异常',
    E_NETWORK_ERROR: '网络异常',
    E_TIME_OUT: '请求超时',
    E_NO_DEF: '未定义的请求错误类型',
  }
  return axiosErrorMap[code] || '未知错误'
}
const enhanceAxiosError = function (error) {
  error.originMessage = error.message
  if (error instanceof axios.Cancel) {
    error.code = 'E_REQUEST_CANCEL'
    error.message = eCodeNMsg('E_REQUEST_CANCEL')
  } else {
    if (error.response) {
      // 理论上只有2种类型的错误，数据格式不匹配，和没通过validate校验
      if (error.originMessage === 'Unexpected Response') {
        error.code = 'E_UNEXCEPTED_RESPONESE'
        error.message = getErrorMessage(error.response) || eCodeNMsg('E_UNEXCEPTED_RESPONESE')
        error.data = error.response.data
      } else {
        // 其他服务器返回错误
        error.code = 'E_SERVER_ERROR'
        error.status = error.response.status
        error.message = getErrorMessage(error.response) || eCodeNMsg('E_SERVER_ERROR')
        error.data = error.response.data
        // 理论上接口的status 只会返回200 和 500
        // switch (error.status) {
        // case 401:
        //   error.message = "登录凭证过期";
        //   break;
        // case 403:
        //   error.message = "禁止访问，请联系管理员！";
        //   break;
        // }
      }
    } else {
      if (error.originMessage === 'Network Error') {
        error.code = 'E_NETWORK_ERROR'
        error.message = eCodeNMsg('E_NETWORK_ERROR')
      } else if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') > -1) {
        error.code = 'E_TIME_OUT'
        error.message = eCodeNMsg('E_TIME_OUT')
      } else {
        error.code = 'E_NO_DEF'
        error.message = eCodeNMsg('E_NO_DEF')
      }
    }
  }
  return error
}
const source = axios.CancelToken.source()
const instance = axios.create({ cancelToken: source.token })
let refreshTokenLock = false
function tryRefreshToken() {
  return new Promise((resolve, reject) => {
    if (!refreshTokenLock) {
      // 一次session 内只会做一次refresh 操作
      refreshTokenLock = true
      return doRefreshToken()
        .then(() => {
          ee.emit('refreshToken', true)
          resolve()
        })
        .catch((e) => {
          console.warn(`[refresh token fail] ${e.message}`)
          reject(e)
        })
    } else {
      ee.once('refreshToken', function () {
        resolve()
      })
    }
  })
}
function authFail(e) {
  // 默认的重定向操作，需要节流
  doLogout(e)
  // 每个请求都需要及时返回错误
  return Promise.reject(e)
}
async function axiosErrorHandle(error) {
  // 不管是否配置interceptors error 都会增强error
  const axiosError = enhanceAxiosError(error)
  if (!axiosError.config || !axiosError.config.interceptors.error) {
    return Promise.reject(axiosError)
  }
  if (is401(axiosError)) {
    const { accessToken, refreshToken } = getAuth()
    if (accessToken && refreshToken) {
      try {
        await tryRefreshToken()
        const result = await instance.request(axiosError.config)
        return result
      } catch (e) {
        // 登出的报错信息只有一个
        e.code = 'E_HAS_RESOLVED'
        // 特殊处理码
        e.exceptionCode = 'E_REFRESH_FAIL'
        return authFail(e)
      }
    } else {
      // 登出的报错信息只有一个
      axiosError.code = 'E_HAS_RESOLVED'
      // 特殊处理码
      axiosError.exceptionCode = 'E_NO_REFRESHTOKEN'
      return authFail(axiosError)
    }
  }

  if (axiosError.config.showError) {
    // 请求取消，和错误已处理 两种状态，不需要再显示
    if (axiosError.code === 'E_HAS_RESOLVED' || axiosError.code === 'E_REQUEST_CANCEL') {
    } else {
      showError(axiosError)
    }
  }
  return Promise.reject(extraErrorHandle(axiosError))
}
instance.interceptors.request.use(
  (_req) => {
    const req = _.defaultsDeep(
      _req,
      _.merge(
        {
          showError: true, // 展示错误的类型
          redirect: true, // 重定向的处理类型
          showLoading: false, //
          showProgress: true,
          postDataType: 'json',
          authType: '',
          interceptors: {
            // 为false 时，不再经过某个interceptor
            request: true,
            response: true,
            error: true,
          },
        },
        getConfig()
      )
    )
    if (!req.interceptors.request) {
      return req
    }
    if (req.showProgress) {
      //  will show progress
      showProgress()
    }
    if (req.showLoading) {
      //  will show loading
      showLoading()
    }
    if (req.method !== 'get') {
      if (req.postDataType === 'formData') {
        req.headers['Content-Type'] = 'multipart/form-data;charset=UTF-8'
        req.transformRequest = (data) => {
          const formData = new FormData()
          for (const i in data) {
            formData.append(i, data[i])
          }
          return formData
        }
      }
      if (req.postDataType === 'form') {
        req.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
        req.transformRequest = (data) => {
          return qs.stringify(data)
        }
      }
      if (req.postDataType === 'json') {
        req.headers['Content-Type'] = 'application/json;charset=UTF-8'
        req.transformRequest = (data) => {
          // 避免空请求体，默认传一个 {}
          return JSON.stringify(data || {})
        }
      }
    }

    if (req.authType === 'Bearer') {
      const { accessToken } = getAuth()
      if (accessToken) {
        req.headers['Authorization'] = `Bearer ${accessToken}`
      }
    }
    req.baseURL = getBaseUrl(req)
    return extraRequestHandle(req)
  },
  (error) => {
    return axiosErrorHandle(error)
  }
)
instance.interceptors.response.use(
  (_res) => {
    // 优先处理进度条和loading的显示
    if (_res.config.showProgress) {
      // will hide progress
      hideProgress(true)
    }
    if (_res.config.showLoading) {
      // will hide loading
      hideLoading()
    }
    if (!_res.config.interceptors.response) {
      return _res
    }
    const res = extraResponseHandle(_res)
    if (validResponse(res)) {
      return Promise.resolve(res.data)
    } else {
      const error = new Error('Unexpected Response')
      Object.assign(error, {
        response: res,
        request: res.request,
        config: res.config,
      })
      return axiosErrorHandle(error)
    }
  },
  (error) => {
    // 优先处理进度条和loading的显示

    if (error instanceof axios.Cancel || error.config?.showProgress) {
      // will hide progress
      hideProgress(false)
    }
    if (error instanceof axios.Cancel || error.config?.showLoading) {
      // will hide loading
      hideLoading()
    }
    return axiosErrorHandle(error)
  }
)
export default instance
export const dangerouslyCancelAllAxios = function () {
  source.cancel('GLOBAL_CANCEL')
}
