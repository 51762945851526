import axiosInstance from '@/plugins/axios'
export const SYSTEM_API_LOGIN = '/mockApi/login'
export const SYSTEM_API_DO_REFRESHTOKEN = '/mockApi/refreshToken'
export const SYSTEM_API_GET_AUTH = '/mockApi/getAuth'

export const login = async ({ username, password, code }) => {
  const res = await axiosInstance.post(SYSTEM_API_LOGIN, { username, password, code })
  return res.data
}

export const doRefreshToken = async ({ refreshToken }) => {
  if (process.env.VUE_APP_USE_MOCK === 'true') {
    const res = await axiosInstance.post(SYSTEM_API_DO_REFRESHTOKEN, { refreshToken })
    return res.data
  }
}

export const getInfo = async () => {
  const res = await axiosInstance.post(SYSTEM_API_GET_AUTH)
  const menus = res.data
  return {
    roles: {
      permissions: {
        menus,
      },
    },
  }
}
